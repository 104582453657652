import * as React from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { TranslatedEntity } from '@api/endpoints/translations'
import { useTranslateValue } from '@hooks/Translation/TranslatedValue'
import { buildEmptyValue, MenuItemEntry } from '@controls/utils'

export const useBuildMenuOptionsFromTranslatedEntities = <T extends TranslatedEntity, TData extends ReadonlyArray<T>>(
  queryResult: UseQueryResult<TData>,
  emptyValueLabel?: string
): Omit<UseQueryResult<ReadonlyArray<MenuItemEntry>>, 'refetch'> => {
  const translateValue = useTranslateValue()

  return React.useMemo(
    () => ({
      ...queryResult,
      data: [
        ...(emptyValueLabel ? [buildEmptyValue(emptyValueLabel)] : []),
        ...(queryResult.data?.map((item) => ({ value: item.id, label: translateValue(item) })) ?? [])
      ]
    }),
    [translateValue, queryResult, emptyValueLabel]
  )
}
